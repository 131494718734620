import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import logo from '../images/logo.png';
import yapay from '../images/yapay.png';
import icono4 from '../images/iconos/facebook.png'
import icono5 from '../images/iconos/instagram.png'
import icono6 from '../images/iconos/tiktok.png';

export default class Footer extends Component {
    render() {
      return (
        <div className="bordes-footer">
            <div className="footer">
                <div className="row">
                    <div className="col-lg-4 footerA">
                        <p className="f-titulo">Informaciòn Adicional:</p>
                        <Link to={'/servicioCliente'} className="f-stitulo">Servicio al cliente</Link><br />
                        <Link to={'/terminosCondiciones'} className="f-stitulo">Terminos y condiciones</Link><br />
                        <Link to={'/libroReclamaciones'} className="f-stitulo">Libro de reclamaciones</Link>
                    </div>
                    <div className="col-lg-4 footerA">
                        <p className="f-titulo">Dolce Salato:</p>
                        <a href="https://bienvenidos.dolcesalato.com.pe/" target="_blank" rel="noopener noreferrer"  className="f-stitulo">Nosotros</a><br />
                        <a href="https://bienvenidos.dolcesalato.com.pe/blog/" target="_blank" rel="noopener noreferrer"  className="f-stitulo">Blog</a><br />
                        <a href="/" className="f-stitulo">Servicios Adicionales</a>
                    </div>
                    <div className="col-lg-4 footerA logo1">
                        <img src={logo} alt="" className="img-footer" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 footerA">
                        <p className="f-titulo">Nuestra Tienda:</p>
                        <p className="f-stitulo">Avenida Angamos 2549, San Borja</p>
                    </div>
                    <div className="col-lg-4 footerA">
                        <p className="f-titulo">Una marca de:</p>
                        <a href="https://www.experiencegroup.com.pe/" target="_blank" rel="noopener noreferrer" className="f-stitulo">Experience Group S.A.C</a>
                    </div>
                    <div className="col-lg-4 footerA logo2">
                        <img src={logo} alt="" className="img-footer" />
                    </div>
                    <div className="col-lg-4 footerA">
                        <p className="f-titulofrase">¡La vida es corta. Disfruta de un Cmon!</p>
                    </div>
                </div>
                <div className="row contactos">
                    <div className="col-12">
                    <p className="f-titulo">Siguenos en:</p>
                        <div className="row">
                            <div className="col-2 imgfooter">
                                <a className="nav-link" href="" target="_blank" rel="noopener noreferrer"><img src={icono4} alt="" /></a>
                            </div>
                            <div className="col-2 imgfooter">
                                <a className="nav-link" href="https://www.instagram.com/mrcmonroll/" target="_blank" rel="noopener noreferrer"><img src={icono5} alt="" /></a>
                            </div>
                            <div className="col-2 imgfooter">
                                <a className="nav-link" href="" target="_blank" rel="noopener noreferrer"><img src={icono6} alt="" /></a>
                            </div> 
                        </div>
                    </div>
                    <div className="col-12 footerA">
                        <p className="f-titulo">Contáctanos:</p>
                        <p className="f-stitulo">contacto@dolcesalato.com.pe</p>
                    </div>
                    <div className="col-12 footerA">
                        <p className="f-titulo">Nuestros Números:</p>
                        <p className="f-stitulo">930 303 295</p>
                    </div>
                </div>
            </div>
            <div className="copy">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-9">
                            <p className="f-ctitulo">&copy; <a href="https://www.experiencegroup.com.pe/" target="_blank" rel="noopener noreferrer" style={{color: "white", textDecoration: "none"}} >Experience Group 2016.</a> Todos los derechos reservados.</p>
                        </div>
                        <div className="col-md-3">
                            <p  className="powered">Powered by <img width="80" height="50" src={yapay} /></p>
                        </div>
                    </div>
                </div>
                
               
            </div>
        </div>
      )
    }
  }  