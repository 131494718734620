import React, {Component} from 'react';
import { Link} from 'react-router-dom';
import Common from "./Common";
/*imagenes*/
import icono1 from '../images/iconos/envelope.png'
import icono2 from '../images/iconos/whatsapp.png'
import icono3 from '../images/iconos/phone.png'
import icono4 from '../images/iconos/facebook.png'
import icono5 from '../images/iconos/instagram.png'
import logo from '../images/logo.png';
import icono6 from '../images/iconos/tiktok.png';

export default class App extends Component {

    constructor(props) {
        super(props);
        
        let sesion = localStorage.getItem('sesion');

        this.state = { 
            ActivaSesion : sesion,
            nombreProducto : '',
            estadoIcono : 0,
            estadoIconoBuscador : 0,
            displayBuscador : "none"
        };

      }


    cambioIcono(e, idIcono){
       e.preventDefault();

       if(idIcono === 1){
           this.setState({estadoIcono : 1})
       }else{
           this.setState({estadoIcono : 0})
       }
    }

    cambioIconoBuscador(e, idIcono){
        e.preventDefault();
 
        if(idIcono === 1){
            this.setState({estadoIconoBuscador : 1, displayBuscador : "flex"})
        }else{
            this.setState({estadoIconoBuscador : 0, displayBuscador : "none"})
        }
     }

    setField (e) {
        
        this.setState({[e.target.name]: e.target.value})
    }

    buscarProduct(e){
       e.preventDefault();
       
       if (e.key === 'Enter') {
        
        localStorage.setItem('nombreProducto', this.state.nombreProducto);

        if(this.state.nombreProducto !== ''){
            window.location.href = '/busqueda'
        }
        
      }
    }


    render() {
      return (
          <div className="stycky-top">
            <nav className="navbar navbar-expand-lg navbar-dark bg-header">
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto ul-1">
                        <li className="nav-item">
                            <a className="nav-link" href="mailto:mrcmon@dolcesalato.com.pe"><img src={icono1} alt="" /> </a>
                        </li>
                        <li className="nav-item mr-4">
                            <a className="nav-link" href="mailto:mrcmon@dolcesalato.com.pe">mrcmon@dolcesalato.com.pe</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://api.whatsapp.com/send/?phone=51930303295&text=Hola+Dolce+Salato%2C+deseo+informaci%C3%B3n+sobre...&app_absent=0" target="_blank" rel="noopener noreferrer"><img src={icono2} alt="" /></a>
                        </li>
                        <li className="nav-item mr-4">
                            <a className="nav-link" href="https://api.whatsapp.com/send/?phone=51930303295&text=Hola+Dolce+Salato%2C+deseo+informaci%C3%B3n+sobre...&app_absent=0" target="_blank" rel="noopener noreferrer">930 303 295</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="tel:+51930303295"><img src={icono3} alt="" /> </a>
                        </li>
                        <li className="nav-item mr-4">
                            <a className="nav-link" href="tel:+51930303295">930 303 295</a>
                        </li>
                    </ul>

                    <ul className="navbar-nav mr-right ul-2">
                        <li className="nav-item">
                            <a className="nav-link" href="# ">Siguenos en:</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" href="https://web.facebook.com/dolceee.salato/?_rdc=1&_rdr" target="_blank" rel="noopener noreferrer"><img src={icono4} alt="" /></a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.instagram.com/mrcmonroll/" target="_blank" rel="noopener noreferrer"><img src={icono5} alt="" /></a>
                        </li>
                        {/* <li className="nav-item mr-2">
                            <a className="nav-link" href="https://vm.tiktok.com/J1oW9dm/" target="_blank" rel="noopener noreferrer"><img src={icono6} alt="" /></a>
                        </li> */}
                        <li className="nav-item">
                            <a href="https://bienvenidos.dolcesalato.com.pe/blog/" target="_blank" rel="noopener noreferrer">
                                <button type="button" className="btn-blog">Blog</button>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-light bg-nav">
                <Link className="navbar-brand" to={'/'} >
                      <img src={logo} width="75" height="75" alt="" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {/* <ul className="navbar-nav mr-left sub-nav">
                        <li className="nav-item dropdown categoria" id="lista">
                            <Link className="nav-link dropdown-toggle" id="navbarDropdownCateg" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to={''}>CATEGORIAS</Link>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownCateg" id="desplegar" to={''}>
                                <Link className="dropdown-item" to={'/postresTortas'} id="PostresTortas">Postres y Tortas</Link>
                                <Link className="nav-link dropdown-toggle" id="navbarDropdownSubCateg" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to={''}>Bocaditos</Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownSubCateg" id="desplegar2">
                                    <Link className="dropdown-item" to={'/bocaditosDulces'}>Bocaditos Dulces</Link>
                                    <Link className="dropdown-item" to={'/bocaditosSalados'}>Bocaditos Salados</Link>
                                    <Link className="dropdown-item" to={'/piqueosSaboresPeruanos'}>Piqueos y Sabores Peruanos</Link>
                                </div>
                                <Link className="nav-link dropdown-toggle" id="lista3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to={''}>Cupcakes</Link>
                                <div className="dropdown-menu" aria-labelledby="lista3" id="desplegar3">
                                    <Link className="dropdown-item" to={'/cupcakesTradicionales'}>Cupcakes Tradicionales</Link>
                                    <Link className="dropdown-item" to={'/cupcakesGigantes'}>Cupcakes Gigantes</Link>
                                </div>
                                <Link className="dropdown-item" to={'/nakedCake'} id="naked">Naked Cake</Link>
                                <Link className="nav-link dropdown-toggle" id="lista4" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to={''}>Jar</Link>
                                <div className="dropdown-menu" aria-labelledby="lista3" id="desplegar4">
                                    <Link className="dropdown-item" to={'/jarCakes'}>Jar Cakes</Link>
                                    <Link className="dropdown-item" to={'/jarChessecakes'}>Jar Cheesecakes</Link>
                                </div>
                                <Link className="dropdown-item" to={'/combos'} id="combos">Combos</Link>
                                <Link className="dropdown-item" to={'/tendencias'} id="tendencias">Tendencias</Link>
                                <Link className="dropdown-item" to={'/fiestasPatrias'} id="fechasEspeciales">Fiestas Patrias</Link>
                                <Link className="dropdown-item" to={'/halloween'} id="tendencias">Halloween</Link>
                                <Link className="dropdown-item" to={'/navidad'} id="tendencias">Navidad</Link>
                                <Link className="dropdown-item" to={'/sanvalentin'} id="tendencias">San Valentín</Link>
                                <Link className="dropdown-item" to={'/diadelamadre'} id="tendencias">Día de la Madre</Link>
                            </div>
                        </li>
                    </ul> */}
                    <ul className="navbar-nav mr-auto ul-busqueda">
                        <li className="nav-item">
                            <div className="box">
                                <div className="container-2">
                                    <Link   className="icon"><i className="fa fa-search"></i></Link>
                                    <input  type="search" id="search" placeholder="¿Qué producto estás buscando?"  name="nombreProducto" onChange={(e)=>this.setField(e)} onKeyPress={(e) => {if (e.key === 'Enter') {this.buscarProduct(e)}}}/>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="navbar-nav mr-right">
                        {/* <li className="nav-item mr-4" id="servicios">
                        <Link className="nav-link dropdown-toggle" id="navbarDropdownCateg" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to={''}>SERVICIOS ADICIONALES</Link>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownCateg" id="desplegar-s2">
                                <Link className="dropdown-item" to={'/decoracionMenaje'}>Decoración y Menaje</Link>
                                <Link className="dropdown-item" to={'/mozosBarman'}>Mozos y Barman</Link>
                                <Link className="dropdown-item" to={'/comidaEventos'}>Comida para Eventos</Link>
                                <Link className="dropdown-item" to={'/coffeeBreak'}>Coffee Break</Link>
                                <Link className="dropdown-item" to={'/snackCart'}>Snack Cart</Link>
                                <Link className="dropdown-item" to={'/degustacionesCorporativas'}>Degustaciones Corporativas</Link>
                            </div>
                        </li> */}
                        <li className="nav-item mr-4" id="cobertura">
                            <Link className="nav-link cobertura" to={'/cobertura'}>COBERTURA</Link>
                        </li>
                        {
                            (this.state.ActivaSesion === '1')?

                            <li className="nav-item" id="ingresar">
                                <Link className="nav-link ingresar" to={'/miCuenta'}>MI CUENTA</Link>
                            </li>

                            :

                            <li className="nav-item" id="ingresar">
                                <Link className="nav-link ingresar" to={'/login'}>INGRESAR</Link>
                            </li>
                        }
                       
                    </ul>
                </div>
            </nav>
            <header className="container-fluid">
                <div className="menu_bar row">
                    <div className="col-3 icono-menu" >
                        {
                            (this.state.estadoIcono === 0)?
                            <i className="fas fa-bars" onClick={(e) => this.cambioIcono(e,1)}></i>
                            :
                            <svg onClick={(e) => this.cambioIcono(e,0)} fill="#ffffff" viewBox="0 0 24 24" type="close" class="sc-bxivhb hXLYRj xmenu"><path d="M21.6 19.673L13.929 12 21.6 4.33A1.364 1.364 0 0019.672 2.4L12 10.072 4.328 2.4a1.364 1.364 0 10-1.929 1.93L10.071 12 2.4 19.673a1.363 1.363 0 001.928 1.928L12 13.93l7.672 7.673a1.363 1.363 0 101.929-1.93z"></path></svg>
                        }
                        
                       
                    </div>

                    <div className="col-6 logo-menu">
                        <a href={Common.urlRedirect}><img src={logo} width="45" height="45" alt="" /></a>
                    </div>
                
                    <div className="col-3 buscador-menu mostrarbuscador">
                        {
                            (this.state.estadoIconoBuscador === 0)?
                            <i className="fas fa-search" onClick={(e) => this.cambioIconoBuscador(e,1)}></i>
                            :
                            undefined
                        }
                    </div>
                </div>
                
            </header>
            <div class="kfbiu2-0 hsBblj sc-iAyFgw dHPsub busqueda-responsive" style={{display : this.state.displayBuscador}}>
                <input type="text" placeholder="¿Qué producto estás buscando?"  name="nombreProducto" onChange={(e)=>this.setField(e)} onKeyPress={(e) => {if (e.key === 'Enter') {this.buscarProduct(e)}}}class="sc-kEYyzF sc-kkGfuU lgDejP" />
                <svg onClick={(e) => this.cambioIconoBuscador(e,0)} fill="#ffffff" viewBox="0 0 24 24" type="close" class="sc-bxivhb hXLYRj mostrarbuscador"><path d="M21.6 19.673L13.929 12 21.6 4.33A1.364 1.364 0 0019.672 2.4L12 10.072 4.328 2.4a1.364 1.364 0 10-1.929 1.93L10.071 12 2.4 19.673a1.363 1.363 0 001.928 1.928L12 13.93l7.672 7.673a1.363 1.363 0 101.929-1.93z"></path>
                </svg>
            </div>
          </div>
      )
    }
  }  