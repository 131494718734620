import React, { Component } from 'react';
import Common from "./Common";
import axios from "axios";
import { Link } from 'react-router-dom';
import Aside from './Aside';
import Footer from './Footer'
import {Sugar} from 'react-preloaders';


import portada1 from '../images/slider/img-1.jpg'
import portada2 from '../images/slider/portada2.png'
import portada3 from '../images/slider/portada3.png'

const config = {
    headers: { Authorization: Common.tokenAPI}
};


export default class Home extends Component {

    constructor(props) {
        super(props);

        const categoriaId = 9;
        const subCategoriaId = 0;
        
        this.state = { 
            sourceCards : [],
            categoriaId: categoriaId,
            subCategoriaId : subCategoriaId,
            estado : 0,
            productoId : '',
            varDisplay: '',
            estadoCesta : false,
            count : 0,
            loading: true,
            dataProductoCarrito : JSON.parse(localStorage.getItem('dataProducto')),
            displayAside : "none",
            cantidad : 0
        };

        
    }

    async componentDidMount(){

        await axios.get(`${Common.urlAPI}/Producto/ProductoCategoria?CategoriaId=${this.state.categoriaId}&SubCategoriaId=${this.state.subCategoriaId}`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    productoId: item.productoId,
                    nombre: item.nombre,
                    imagen : item.imagen,
                    clasificacion : item.clasificacion,
                    cantidad : item.cantidad,
                    precio : item.precio,
                    diasEntrega : item.diasEntrega
                };
            });

            this.setState({sourceCards : source, categoriaId: '0', subCategoriaId: '0', loading : false});
        })
    }
      

    AddProducto(e,productoId){
        
        e.preventDefault();
        var newCount = this.state.count + 1 
        this.setState({estado : 1, productoId : productoId, varDisplay: 'none', estadoCesta : true, count :  newCount});
    }

    componentDidUpdate(prevProps,snapshot){
        
        if(this.state.estado === 1){
            localStorage.setItem('productoId',this.state.productoId);

            this.setState({estado : 0})
        }

    }


    contadorProductos = (cantidad) =>{
       this.setState({cantidad : cantidad})
    }
    

    mostarAside(e){
        e.preventDefault();

        this.setState({displayAside: "block"})
    }

  render() {

    return (
      <div className="container-fluid bordes-ds">
        <section className="col-md-9 col-lg-9 col-xl-9">
        <div id="carouselExampleCaptions" className="carousel slide bg-slider" data-ride="carousel">
              <ol className="carousel-indicators">
                  <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                  {/* <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                  <li data-target="#carouselExampleCaptions" data-slide-to="2"></li> */}
              </ol>
              <div className="carousel-inner">
                  <div className="carousel-item active">
                      <img className="d-block w-100" src={portada1} alt="" />
                      <div className="carousel-caption d-none d-md-block">
                          {/* <p className="tp-cotiza">¿Deseas personalizar tu pedido?</p>
                          <p className="tp-scotiza">Resérvalo con 5 o 7 dias de anticipación.</p>
                          <Link to={'/cotizacion'}><button type="button" className="btn-cotiza">Cotiza Aqui</button></Link> */}
                      </div>
                  </div>
                  {/* <div className="carousel-item">
                      <img className="d-block w-100" src={portada2} alt="" />
                      <div className="carousel-caption d-none d-md-block">
                          <p className="tp-cotiza">¿Quieres cocinar deliciosos postres desde casa?</p>
                          <p className="tp-scotiza">Revisa nuestras recetas y tips de cocina.</p>
                          <a href="https://bienvenidos.dolcesalato.com.pe/blog/" target="_blank" rel="noopener noreferrer" ><button type="button" className="btn-cotiza">Ingresar</button></a>
                      </div>
                  </div>
                  <div className="carousel-item">
                      <img className="d-block w-100" src={portada3} alt="Third slide" />
                      <div className="carousel-caption d-none d-md-block">
                          <p className="tp-cotiza">¿Quieres conocer más sobre Dolce Salato?</p>
                          <p className="tp-scotiza">Momentos dulces e inolvidables para ti.</p>
                          <a href="https://bienvenidos.dolcesalato.com.pe/" target="_blank" rel="noopener noreferrer" ><button type="button" className="btn-cotiza">Visítanos</button></a>
                      </div>
                  </div> */}
              </div>
          </div>
          
          <div className="tarjetapro">
            <h1 >ROLLITOS DE CANELA</h1>
            <div className="card-columns">
                {
                    this.state.sourceCards.map(item => {
                         return (

                            item.clasificacion === 1 ?
                            <div>
                                <div className="card-responsive row">
                                    <div className="col-4 card-img-responsive">
                                    <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}}>
                                        <img src={Common.urlIMG+item.imagen}  alt="" />
                                    </Link>
                                    </div >
                                    <div className="col-5 card-body-responsive">
                                        <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}}>
                                            <h5 className='card-title'>{item.nombre}</h5>
                                        </Link>
                                        <p className='card-text'>
                                            {
                                                item.cantidad === '1' ?
                                                item.cantidad + ' Unidad'
                                                :
                                                item.cantidad + ' Unidades'
                                            }
                                        </p>
                                        <p className='card-precio'>S/ {item.precio}.00</p>
                                    </div >
                                    <div className="col-3 card-bottom">
                                        <Link onClick={(e)=>this.AddProducto(e, item.productoId)}>
                                            <div className="bottom">
                                                 <i class="fa fa-plus fa-2x" aria-hidden="true"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='card mb-2' key={item.productoId}>
                                    <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}}>
                                        <img src={Common.urlIMG+item.imagen} className='card-img-top' alt="" />
                                    </Link>
                                    <div className='card-body'>
                                        <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}}>
                                            <h5 className='card-title'>{item.nombre}</h5>
                                        </Link>
                                        <p className='card-text'>
                                            {
                                                item.cantidad === '1' ?
                                                item.cantidad + ' Unidad'
                                                :
                                                item.cantidad + ' Unidades'
                                            }
                                        </p>
                                        <p className='card-precio'>S/ {item.precio}.00</p>
                                        <button className="btn-veropciones btn-add-prod-1" onClick={(e)=>this.AddProducto(e, item.productoId)}><i className="fas fa-cart-plus"></i> AGREGAR</button>
                                    </div>
                                </div>
                            </div>  
                            :
                            <div>
                                <div className="card-responsive row">
                                    <div className="col-4 card-img-responsive">
                                    <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}}>
                                        <img src={Common.urlIMG+item.imagen} className='card-img-top' alt="" />
                                    </Link>
                                    </div >
                                    <div className="col-5 card-body-responsive">
                                        <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}}>
                                            <h5 className='card-title'>{item.nombre}</h5>
                                        </Link>
                                        <p className='card-text'>Porciones, Sabores y más</p>
                                    </div >
                                    <div className="col-3 card-bottom">
                                        <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}}>
                                            <div className="bottom-opciones">
                                                 <i class="fa fa fa-chevron-right fa-2x" aria-hidden="true"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='card mb-2' key={item.productoId}>
                                    <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}}>
                                        <img src={Common.urlIMG+item.imagen} className='card-img-top' alt="" />
                                    </Link>
                                    <div className='card-body'>
                                        <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}}>
                                            <h5 className='card-title'>{item.nombre}</h5>
                                        </Link>
                                        <p className='card-text'>Porciones, Sabores y más</p>
                                        <Link to={{pathname: '/detalleProducto', state : {productoId : item.productoId}}} className="btn btn-veropciones">VER OPCIONES</Link>
                                    </div>
                                </div>
                            </div>  
                           
                         )
                    })
                }
            </div>
           <Sugar color= "#FBC271" customLoading={this.state.loading} />
          </div>
          <Footer />
          </section>
          <aside className="col-md-3 col-lg-3 bg-aside aside aside-1 bordes-ds" style={{display: this.state.displayAside}}>
                <Aside
                state={{
                    producto : this.state.productoId,
                    varDisplay : this.state.varDisplay,
                    estadoCesta : this.state.estadoCesta,
                    count : this.state.count
                }}
                
                history = {this.props.history}

                contadorProductos = {this.contadorProductos}
                />
        </aside>
        <div className="i2ljv5-0 bFGuQ mostrar-aside">
            <button onClick={(e) => this.mostarAside(e)} class="sc-htpNat dyjgNq"><svg fill="#ffffff" viewBox="0 0 24 24" type="cart" class="sc-bxivhb hXLYRj"><path d="M21.333 10.323h-1.7l-5.466-6.1a.673.673 0 00-1 .9l4.666 5.233H6.167l4.666-5.233a.673.673 0 10-1-.9l-5.433 6.1H2.667a.667.667 0 100 1.333h18.666a.667.667 0 100-1.333zM5.333 20.99h13.334l1.6-8h-16.5l1.566 8z"></path></svg>
            <span>
                {
                   this.state.cantidad
                }
            </span>
            </button>
        </div>
      </div>
    );
  }
}
